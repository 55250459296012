import "./App.css";

import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";

export function CardDefault() {
  return (
    <Card className="mt-7 w-screen">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://images.unsplash.com/photo-1598791318878-10e76d178023?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="card-image"
        />
      </CardHeader>
      <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
          To Do app
        </Typography>
        <Typography>
          A simple task manager with basic CRUD functionality
        </Typography>
      </CardBody>
      <CardFooter className="pt-0">
        <a
          href="https://stephentodo.42web.io"
          target="_blank"
          className="inline-block"
        >
          <Button>Visit Site</Button>
        </a>
      </CardFooter>
    </Card>
  );
}

export function CardDefault1() {
  return (
    <Card className="mt-7 w-screen">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://images.unsplash.com/photo-1580974928064-f0aeef70895a?q=80&w=3174&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="card-image"
        />
      </CardHeader>
      <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
          E Commerce Store
        </Typography>
        <Typography>A simple online electronics store</Typography>
      </CardBody>
      <CardFooter className="pt-0">
        <a
          href="https://bazingaelectronics.lovestoblog.com"
          className="inline-block"
          target="_blank"
        >
          <Button>Visit Site</Button>
        </a>
      </CardFooter>
    </Card>
  );
}

export function CardDefault2() {
  return (
    <Card className="mt-7 w-screen">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://images.unsplash.com/photo-1705077031869-51b60754302a?q=80&w=2909&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="card-image"
        />
      </CardHeader>
      <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
          Weather App
        </Typography>
      </CardBody>
      <CardFooter className="pt-0">
        <a
          href="https://weather-app.is-great.net"
          target="_blank"
          className="inline-block"
        >
          <Button>Visit Site</Button>
        </a>
      </CardFooter>
    </Card>
  );
}

export function CardDefault3() {
  return (
    <Card className="mt-7 w-screen">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://images.unsplash.com/photo-1593941707882-a5bba14938c7?q=80&w=2944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="card-image"
        />
      </CardHeader>
      <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
          EV Store
        </Typography>
        <Typography>Simple EV store: React, Javascript, JSX</Typography>
      </CardBody>
      <CardFooter className="pt-0">
        <Button>Visit Site</Button>
      </CardFooter>
    </Card>
  );
}

export function HorizontalCard() {
  return (
    <Card className="w-full m-2 flex-row">
      <CardHeader
        shadow={false}
        floated={false}
        className="m-0 w-2/5 shrink-0 rounded-r-none"
      >
        <img
          src="https://images.unsplash.com/photo-1598791318878-10e76d178023?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="card-image"
          className="h-full w-full object-cover"
        />
      </CardHeader>
      <CardBody>
        <Typography variant="h4" color="blue-gray" className="mb-2">
          To Do app
        </Typography>
        <Typography color="gray" className="mb-8 font-normal">
          A simple task manager with basic CRUD functionality
        </Typography>
        <a
          href="https://stephentodo.42web.io"
          target="_blank"
          className="inline-block"
        >
          <Button variant="text" className="flex items-center gap-2">
            Visit Site
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-4 w-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </Button>
        </a>
      </CardBody>
    </Card>
  );
}

export function HorizontalCard1() {
  return (
    <Card className="w-full m-2  flex-row">
      <CardHeader
        shadow={false}
        floated={false}
        className="m-0 w-2/5 shrink-0 rounded-r-none"
      >
        <img
          src="https://images.unsplash.com/photo-1580974928064-f0aeef70895a?q=80&w=3174&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="card-image"
          className="h-full w-full object-cover"
        />
      </CardHeader>
      <CardBody>
        <Typography variant="h4" color="blue-gray" className="mb-2">
          E Commerce Store
        </Typography>
        <Typography color="gray" className="mb-8 font-normal">
          A simple online electronics store
        </Typography>

        <a
          href="https://bazingaelectronics.lovestoblog.com"
          className="inline-block"
          target="_blank"
        >
          <Button variant="text" className="flex items-center gap-2">
            Visit Site
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-4 w-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </Button>
        </a>
      </CardBody>
    </Card>
  );
}

export function HorizontalCard2() {
  return (
    <Card className="w-full m-2  flex-row">
      <CardHeader
        shadow={false}
        floated={false}
        className="m-0 w-2/5 shrink-0 rounded-r-none"
      >
        <img
          src="https://images.unsplash.com/photo-1705077031869-51b60754302a?q=80&w=2909&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="card-image"
          className="h-full w-full object-cover"
        />
      </CardHeader>
      <CardBody>
        <Typography variant="h4" color="blue-gray" className="mb-2">
          Weather App
        </Typography>

        <a
          href="https://weather-app.is-great.net"
          target="_blank"
          className="inline-block"
        >
          <Button variant="text" className="flex items-center gap-2">
            Visit Site
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-4 w-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </Button>
        </a>
      </CardBody>
    </Card>
  );
}

export function HorizontalCard3() {
  return (
    <Card className="w-full m-2  flex-row">
      <CardHeader
        shadow={false}
        floated={false}
        className="m-0 w-2/5 shrink-0 rounded-r-none"
      >
        <img
          src="https://images.unsplash.com/photo-1593941707882-a5bba14938c7?q=80&w=2944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="card-image"
          className="h-full w-full object-cover"
        />
      </CardHeader>
      <CardBody>
        <Typography variant="h4" color="blue-gray" className="mb-2">
          EV Store
        </Typography>
        <Typography color="gray" className="mb-8 font-normal">
          Simple EV store: React, Javascript, JSX
        </Typography>
        <a
          href="https://electrifys.shop"
          target="_blank"
          className="mb-inline-block"
        >
          <Button variant="text" className="flex items-center gap-2">
            Visit Site
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-4 w-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </Button>
        </a>
      </CardBody>
    </Card>
  );
}

function Work() {
  return (
    <div>
      <div className="block lg:hidden">
        <CardDefault />
        <CardDefault1 />
        <CardDefault2 />
        <CardDefault3 />
      </div>

      <div className="hidden lg:block">
        <HorizontalCard />
        <HorizontalCard1 />
        <HorizontalCard2 />
        <HorizontalCard3 />
      </div>
    </div>
  );
}

export default Work;
