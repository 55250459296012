import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Container from "react-bootstrap/Container";

import About from "./about";
import Home from "./home";
import { Avatar } from "flowbite-react";
import { BrowserRouter, Link } from "react-router-dom";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      alert("Please enter a valid email address.");
      return;
    }

    setLoading(true);

    const serviceID = "service_vgs294g";
    const templateID = "template_2tn2dde";
    const userID = "rpmyDEHEMrEZ5nS1z";

    try {
      // Send email to your own inbox
      await emailjs.send(serviceID, templateID, formData, userID);

      // Send acknowledgment email to the user
      const acknowledgmentTemplateID = "template_qcwhpg8"; // Add this in EmailJS
      const acknowledgmentData = {
        email: formData.email, // Recipient's email
        name: formData.firstName, // Customize the email with user's name
      };

      await emailjs.send(
        serviceID,
        acknowledgmentTemplateID,
        acknowledgmentData,
        userID
      );

      alert(
        "Message sent successfully! You will receive an acknowledgment email shortly."
      );

      // Reset form
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
      });
    } catch (error) {
      alert("Failed to send message. Please try again.");
    }

    setLoading(false);
  };

  return (
    <div
      className="bg-white px-6 py-16 sm:py-20 lg:px-8 flex justify-center"
      id="contact"
    >
      <div className="max-w-lg w-full">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">
          Get In Touch
        </h2>
        <p className="mt-2 text-lg leading-8 text-gray-600 text-center">
          Contact Form
        </p>
        <form className="mt-8" onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 gap-y-4">
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              className="border border-black px-3 py-2 rounded-md"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              className="border border-black px-3 py-2 rounded-md"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="border border-black px-3 py-2 rounded-md"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="tel"
              name="phone"
              placeholder="Phone Number"
              className="border border-black px-3 py-2 rounded-md"
              value={formData.phone}
              onChange={handleChange}
            />
            <textarea
              name="message"
              placeholder="Your Message"
              className="border border-black px-3 py-2 rounded-md h-32"
              value={formData.message}
              onChange={handleChange}
              required
            />
            <button
              type="submit"
              className="bg-black text-white px-4 py-2 rounded-md text-sm w-full flex justify-center items-center"
            >
              {loading ? "Sending..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
