import logo from "./logo.svg";
import "./App.css";
import { Button } from "@material-tailwind/react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Avatar,
  Tooltip,
} from "@material-tailwind/react";

import { LinkedinOutlined, GithubOutlined } from "@ant-design/icons";
export function TypographyDefault() {
  return (
    <Typography variant="h4" color="gray">
      Hello there! It's nice to meet you. I'm Stephen, I'm very passionate about
      technology and I enjoy taking on challenges. I studied Computer Science
      and Computing at Bowen University and Teesside University respectively. I
      enjoy coding, football, arts, and music.
    </Typography>
  );
}

export function BackgroundBlogCard() {
  return (
    <Card
      shadow={false}
      className="relative grid h-[40rem] w-full  items-center justify-center overflow-hidden text-center"
    >
      <CardHeader
        floated={false}
        shadow={false}
        color="transparent"
        className="absolute inset-0 m-0 h-full w-full rounded-none bg-[url('https://images.unsplash.com/photo-1494173853739-c21f58b16055?q=80&w=3165&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')] bg-cover bg-center"
      >
        <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
      </CardHeader>
      <CardBody className="relative py-14 px-6 md:px-12"></CardBody>
    </Card>
  );
}

function About() {
  return (
    <div className="w-screen h-screen">
      <div className="justify-center bg-gradient-to-tr from-white to-slate h-full w-screen flex items-center ">
        <div className="w-2/4  items-center m-2 hidden md:block ">
          <BackgroundBlogCard />
        </div>

        <div className="w-2/4 items-center m-3 ">
          <a
            href="https://www.linkedin.com/in/stephen-adegbile-663537138"
            target="_blank"
          >
            <LinkedinOutlined style={{ fontSize: "450%" }} />
          </a>
          <a href="https://github.com/Stephen0111" target="_blank">
            <GithubOutlined style={{ fontSize: "480%" }} />
          </a>

          <TypographyDefault />
          <div className="space-y-1 font-medium dark:text-white">
            <div className="text-xl text-black dark:text-gray-400 text-center"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
